<template>
  <div>
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">{{
        isEdit ? "Edit Premium Package" : "Add New Premium Package"
      }}</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid" ref="packageForm">
        <v-text-field label="Package Type" :hide-details="true" class="d-none">
        </v-text-field>
        <v-text-field
          v-model="count"
          @keypress.enter="addCountHandler('ProductsCount')"
          class="mt-5"
          label="Item Count"
          :hide-details="true"
          :disabled="disabledCount"
          type="number"
        >
        </v-text-field>
        <div class="item-counts mt-3">
          <span
            class="d-inline-block mr-2 font-14 cursor-pointer"
            v-for="(product, i) in obj.numberOfProducts.filter(
              (item) => item.number
            )"
            :key="i"
            @click="removeCount(product, i, 'ProductsCount')"
          >
            {{ product.number }}
          </span>
        </div>

        <v-text-field
          v-model="PreviewCount"
          @keypress.enter="addCountHandler('PreviewCount')"
          class="mt-5"
          label="Preview Count"
          :hide-details="true"
          :disabled="disabledPreviwCount"
          type="number"
        >
        </v-text-field>
        <div class="item-counts mt-3">
          <span
            class="d-inline-block mr-2 font-14 cursor-pointer"
            v-for="(preview, i) in obj.numberOfPreviews.filter(
              (item) => item.number
            )"
            :key="i"
            @click="removeCount(preview, i, 'PreviewCount')"
          >
            {{ preview.number }}
          </span>
        </div>

        <span class="d-block font-20 mt-5">Theme Preview</span>
        <div class="item-counts mb-1">
          <span
            class="font-14 d-inline-block mr-3 cursor-pointer"
            :class="theme.selected ? 'theme-active' : ''"
            v-for="(theme, i) in availableThemes"
            @click="selectTheme(theme)"
            :key="i"
            >{{ theme.name }}
            </span
          >
        </div>

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          @click="addItem"
          ><span class="white--text font-16">{{
            isEdit ? "Update" : "Done"
          }}</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters} from "vuex";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    count: null,
    PreviewCount: null,
    obj: { numberOfProducts: [], numberOfPreviews: [] },
    valid: false,
    disabledCount: false,
    disabledPreviwCount: false,
    availableThemes: [],
  }),
  computed: {
    ...mapGetters(["themes"]),
  },
  methods: {
    ...mapMutations(["SET_ALL_THEMES"]),
    addCountHandler(cuurentCountItem) {
      let countItem;
      let countNumber;
      // eslint-disable-next-line no-unused-vars
      let TheDisabledCount;
      if (cuurentCountItem === "ProductsCount") {
        countItem = "numberOfProducts";
        countNumber = this.count;
        TheDisabledCount = this.disabledCount;
      } else if (cuurentCountItem === "PreviewCount") {
        countItem = "numberOfPreviews";
        countNumber = this.PreviewCount;
        TheDisabledCount = this.disabledPreviwCount;
      }
      if (this.isEdit) {
        let firstIndexWithoutNumber = this.obj[countItem].findIndex(
          (item) => !item.number
        );
        // //console.log(this.item, "tt", this.obj);
        this.obj[countItem][firstIndexWithoutNumber].number = countNumber;

        if (
          this.obj[countItem].filter((item) => item.number).length ===
          this.item[countItem].length
        ) {
          if (cuurentCountItem === "ProductsCount") {
            this.disabledCount = true;
          } else if (cuurentCountItem === "PreviewCount") {
            this.disabledPreviwCount = true;
          }
        } else {
          if (cuurentCountItem === "ProductsCount") {
            this.disabledCount = false;
          } else if (cuurentCountItem === "PreviewCount") {
            this.disabledPreviwCount = false;
          }
        }
        cuurentCountItem === "ProductsCount"
          ? (this.count = "")
          : cuurentCountItem === "PreviewCount"
          ? (this.PreviewCount = "")
          : false;
      } else {
        this.obj[countItem].push({ number: countNumber });
        cuurentCountItem === "ProductsCount"
          ? (this.count = "")
          : cuurentCountItem === "PreviewCount"
          ? (this.PreviewCount = "")
          : false;
      }
    },
    removeCount(itemRemoved, index, cuurentCountItem) {
      let countItem;
      // eslint-disable-next-line no-unused-vars
      let TheDisabledCount;
      if (cuurentCountItem === "ProductsCount") {
        countItem = "numberOfProducts";
        TheDisabledCount = this.disabledCount;
      } else if (cuurentCountItem === "PreviewCount") {
        countItem = "numberOfPreviews";
        TheDisabledCount = this.disabledPreviwCount;
      }
      if (this.isEdit) {
        let index = this.item[countItem].findIndex(
          (item) => itemRemoved.numericId == item.numericId
        );
        this.obj[countItem][index].number = "";
        if (
          this.obj[countItem].filter((item) => item.number).length ===
          this.item[countItem].length
        ) {
          if (cuurentCountItem === "ProductsCount") {
            this.disabledCount = true;
          } else if (cuurentCountItem === "PreviewCount") {
            this.disabledPreviwCount = true;
          }
        } else {
          if (cuurentCountItem === "ProductsCount") {
            this.disabledCount = false;
          } else if (cuurentCountItem === "PreviewCount") {
            this.disabledPreviwCount = false;
          }
        }

        return;
      }
      this.obj[countItem].splice(index, 1);
    },
    selectTheme(theme) {
      this.obj.theme = [];
      this.availableThemes = this.availableThemes.map((th) => {
        if (theme.numericId === th.numericId) {
          if (th.selected) {
            return { ...th, selected: false };
          } else {
            return { ...th, selected: true };
          }
        }
        return th;
      });
    },

    async addItem() {
      console.log("ADD ITEM");
      this.obj.theme = this.availableThemes
        .filter((th) => th.selected)
        .map((th) => th.numericId);
      if (
        !this.obj.theme ||
        this.obj.numberOfProducts.length == 0 ||
        this.obj.numberOfPreviews.length == 0
      ) {
        console.log(
          this.obj.theme,
          this.obj.numberOfProducts.length,
          this.obj.numberOfPreviews.length
        );
        this.$store.dispatch("showSnack", {
          text: "Please check the fields",
          color: "error",
        });
        return;
      }

      let res;

      if (this.isEdit) {
        //console.log(this.obj.numberOfProducts.every((c) => c.number));
        this.obj.numberOfPreviews = this.obj.numberOfPreviews.filter(
          (i) => i.number
        );
        this.obj.numberOfProducts = this.obj.numberOfProducts.filter(
          (i) => i.number
        );

        if (!this.obj.numberOfProducts.every((c) => c.number)) {
          this.$store.dispatch("showSnack", {
            text: "Please check the fields",
            color: "error",
          });
          return;
        }

        this.$axios
          .patch(`${process.env.VUE_APP_PACKAGES}/${this.item._id}`, {
            ...this.obj,
          })
          .then((res) => {
            this.$store.dispatch("showSnack", {
              text: "Package Added Successfully",
              color: "success",
            });
            this.$emit("getData");
          })
          .catch((e) => {
            console.log("error: ", e);
            this.$store.dispatch("showSnack", {
              text: e.message || e.error || "Something went wrong!",
              color: "error",
            });
          });
      } else {
        this.obj.type = "Premium";
        this.obj.numberOfProducts = this.obj.numberOfProducts.map(
          (c) => c.number
        );
        this.obj.numberOfPreviews = this.obj.numberOfPreviews.map(
          (c) => c.number
        );
        console.log("obj: ", this.obj);
        this.$axios
          .post(process.env.VUE_APP_PACKAGES, {
            ...this.obj,
          })
          .then((res) => {
            this.$store.dispatch("showSnack", {
              text: "Package Added Successfully",
              color: "success",
            });
            this.$emit("getData");
          })
          .catch((e) => {
            console.log("error: ", e);
            this.$store.dispatch("showSnack", {
              text: e.message || e.error || "Something went wrong!",
              color: "error",
            });
          });
      }
    },
  },
  async created() {
    console.log('Packes' , process.env.VUE_APP_ADMIN_THEMES)
    let res = await this.$axios.get(process.env.VUE_APP_ADMIN_THEMES)
      this.SET_ALL_THEMES(res.data);
       res.data.forEach((th) => {
        th.selected = false;
      });
      this.availableThemes = this.themes
      this.availableThemes.forEach((th) => {
        th.selected = false;
      });
    if (this.isEdit) {
      this.obj = this.$structuredClone(this.item);
      this.availableThemes.forEach((th) => {
        this.obj.theme.forEach((theme) => {
          if (theme == th.numericId) {
            th.selected = true;
          }
        });
      });
      this.disabledCount = true;
      this.disabledPreviwCount = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.item-counts {
  span {
    padding: 5px 10px;
    background: #d2e8e3;
    border-radius: 16px;
    transition: 0.3s;
  }
}
.theme-active {
  background: $primary !important;
  color: white;
}
</style>
