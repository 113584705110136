<template>
  <div class="admin-page">
    <!-- header -->
    <div class="d-flex align-center justify-space-between mt-5 mr-5">
      <span class="font-32">Packages</span>
      <div class="d-flex align-center cursor-pointer" @click="newpackage">
        <span class="font-22 font-600">New Package</span>
        <span
          class="font-30 d-inline-block px-3 white--text primary ml-2 radius-5"
          >+</span
        >
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="data"
      class="elevation-1 mt-5"
      mobile-breakpoint=""
    >
      <template v-slot:item.verified="{ item }">
        <v-switch
          class="mt-0 mr-0"
          :hide-details="true"
          v-model="item.active"
          inset
          @change="switchHandler($event, item)"
        ></v-switch>
      </template>
      <!-- <template v-slot:item.theme="{ item }">
        <span v-for="(text, i) in item.theme" :key="i"
          >{{ text }}
          <span v-if="i < item.theme.length - 1"> - </span>
        </span>
      </template> -->
      <template v-slot:item.numberOfProducts="{ item }">
        <span v-for="(text, i) in item.numberOfProducts" :key="i"
          >{{ text.number }}
          <span v-if="i < item.numberOfProducts.length - 1"> - </span>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center justify-center">
          <img
            @click="editHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/setting-black.svg')"
            alt=""
          />
          <img
            @click="deleteHandler(item)"
            class="mx-2 cursor-pointer"
            :src="require('@/assets/images/icons/delete-black.svg')"
            alt=""
          />
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="packageDialog"
      max-width="500"
      content-class="bg-grey radius-15"
      :key="packageDialog"
    >
      <package-dialog
        @close="packageDialog = false"
        @getData="getDataDialog"
        :isEdit="isEdit"
        :item="selectedItem"
      ></package-dialog>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-dialog
        @close="deleteDialog = false"
        @getData="getDataDialog"
        :urlApi="packagesApiUrl"
        :itemID="selectedItem._id"
      ></delete-dialog>
    </v-dialog>
  </div>
</template>

<script>
import PackageDialog from "@/components/dialogs/NewPackage";
export default {
  components: {
    PackageDialog,
  },
  data: () => ({
    packageDialog: false,
    deleteDialog: false,
    isEdit: false,
    headers: [
      {
        text: "Package Type",
        align: "start",
        sortable: false,
        value: "type",
      },
      { text: "Number Of Products", value: "numberOfProducts" },
      { text: "Verified", value: "verified" },
      { text: "Actions", value: "actions", align: "center" },
    ],
    data: [],
    selectedItem: {},
  }),
  computed: {
    packagesApiUrl() {
      return process.env.VUE_APP_PACKAGES;
    },
  },
  methods: {
    async switchHandler(event, item) {
      await this.$axios.put(`${process.env.VUE_APP_PACKAGES}/${item._id}`, {
        active: event,
      });
    },

    async getData() {
      let res = await this.$axios.get(process.env.VUE_APP_PACKAGES);
      if (res.status == 200) {
        this.data = res.data.packages;
      }
    },
    async getDataDialog() {
      this.packageDialog = false;
      this.isEdit = false;
      await this.getData();
    },
    newpackage() {
      this.packageDialog = true;
      this.isEdit = false;
      this.selectedItem = {};
    },
    editHandler(item) {
      this.isEdit = true;
      this.packageDialog = true;
      this.selectedItem = item;
    },
    deleteHandler(item) {
      this.deleteDialog = true;
      this.selectedItem = item;
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteEnter(_, _2, next) {
    if (localStorage.getItem("role") == "admin") {
      next();
    } else {
      next("/");
    }
  },
};
</script>

<style lang="scss">
.admin-page {
  padding: 0px 4%;
}

.v-data-table-header {
  background: $primary;

  span {
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
}
</style>
